import { Outlet, Link, useNavigate, router, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { AppValues, API, ApiAdmin } from "../../Values";
import { Modal, Button, Dropdown } from "react-bootstrap";
import axios from "axios";
import Cookies from "js-cookie";
import LoginCheck from "../../LoginCheckAdmin/page";
import Header_ from "../compoenents/Header_";
export default function AdminEmpolyee() {
  const navigate = useNavigate();
  //===========Check Login ================
  LoginCheck();
  var admininfo = [];
  var AdminName = "";
  var AdminID = "";
  if (Cookies.get("admininfo")) {
    admininfo = JSON.parse(Cookies.get("admininfo"));
    AdminName = admininfo[0].Name;
    AdminID = admininfo[0].ID;
  }
  //----------------------------------------
  const [SearchText, SetSearchText] = useState("");
  const [Data, SetData] = useState([]);
  useEffect(() => {
    GetEmployees();
  }, []);
  //-------------------------------------------------------------
  async function GetEmployees() {
    try {
      SetData([]);
      const post = {
        Action: "AdminEmployees",
        SearchText: SearchText,
      };
      console.log(post);
      const response = await fetch(ApiAdmin + "/Employees.php", {
        method: "POST",
        body: JSON.stringify(post),
      });
      const data = await response.json();
      console.log(data);

      SetData(data.data);
    } catch (error) {
      return error;
    }
  }

  const handleChange = (e) => {
    SetSearchText(e.target.value);
    console.log(SearchText);
    // GetEmployees();
  };
  const handleTextClear = (e) => {
    SetSearchText("");
  };

  //-------------------------------------------------------------
  async function handeldelete(ID, Name) {
    if (window.confirm(`هل تريد حذف  ${Name} `)) {
      try {
        const post = {
          Action: "Delete",
          ID: ID,
          Name: Name,
        };
        const response = await fetch(ApiAdmin + "/Employees.php", {
          method: "POST",
          body: JSON.stringify(post),
        });
        const data = await response.json();
        console.log(data);
        GetEmployees()
      } catch (error) {
        return error;
      }
    }
  }
  return (
    <>
      <Header_ />
      <div dir="rtl" className="row p-0 m-0 mt-0 text-center">
        <h3 className="text-orange fw-bold py-1">الموظفين</h3>
        <div className="col-1">
          <Link to="../EmpolyeeAdd" className="btn btn-green p-0 px-2">اضافة</Link>
        </div>
        <div className="col-lg-11 col-12 my-2">
          <div className="div-SearchText">
            <input
              type="text"
              name="SearchText"
              className="SearchText"
              onChange={handleChange}
              onClick={handleTextClear}
              value={SearchText}
              autoComplete="off"
              // placeholder="بحث برقم تليفون او رقم اوردر او اسم مندوب"
            />
            <button className="btn btn-warning m-0" onClick={GetEmployees}>
              Search
            </button>
          </div>
        </div>
        <div className="col-12 mt-0 bg-light0 text-center">
          <div className="table-responsive">
            <table
              dir="rtl"
              className="table table-striped  m-auto"
              style={{ minWidth: "1220px" }}
            >
              <thead>
                <tr>
                  <th width="120px">الاسم</th>
                  <th width="150px">عنوان</th>
                  <th width="80px">الميلاد</th>
                  <th width="60px">البطاقه</th>
                  <th width="80px">موبايل</th>
                  <th width="50px">الوظيفة</th>
                  <th width="80px"> تاريخ التوظيف</th>
                  <th width="80px"> المرتب</th>
                  <th width="60px"> الحالة</th>
                  <th width="30px"> حذف</th>
                </tr>
              </thead>
              <tbody>
                {Data.map((x, index) => {
                  return (
                    <tr key={index}>
                      <td>{x.Name}</td>
                      <td>{ x.Address}</td>
                      <td className="text-center">{x.Age}</td>
                      <td className="text-center">{x.Card}</td>
                      <td className="text-center">{x.Mobail}</td>
                      <td className="text-center">{x.Job}</td>
                      <td className="text-center">{x.DateJob}</td>
                      <td className="text-center">{x.Selary}</td>
                      <td className="text-center">{x.Statue}</td>
                      <td className="text-center">
                        <span
                          className="text-danger fw-bold p-3"
                          onClick={() => handeldelete(x.ID, x.Name)}
                        >
                          {"X "}
                        </span>
                      </td>
                    </tr>
                  );
                       })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
