import { Outlet, Link, router, useParams, useNavigate } from "react-router-dom";
import { AppValues, API, ApiUrl } from "../../Values";
import Cookies from "js-cookie";
export default function AccountDisable() {
  const navigate = useNavigate();
  var userinfo = [];
  var Username = "";
  var UserID = "";
  if (Cookies.get("userinfo")) {
    userinfo = JSON.parse(Cookies.get("userinfo"));
    Username = userinfo[0].Name;
    UserID = userinfo[0].ID;
  }

  const LogOut = async () => {
    try {
      const post = {
        Name: Username,
        ID: UserID,
        Action: "warking",
        warking: "متوقف",
      };
      const result = await fetch(API.DellerAccount, {
        method: "POST",
        body: JSON.stringify(post),
      });
      const data = await result.json();
      console.log(data);
      if (data.msg == "success") {
        Cookies.set("LoginType", "", { expires: 365 }); // Sets a cookie for 365 days
        Cookies.set("userinfo", "", { expires: 365 }); // Sets a cookie for 365 days
        Cookies.remove("userinfo");
        Cookies.remove("LoginType");
        return navigate("../login");
      }
    } catch (error) {
      return error;
    }
  };
  return (
    <>
      <div className="row p-4">
        <div className="col-12 text-center pt-4 ">
          <br />
          <br />
          <br />
          <i
            class="fa-solid fa-user-slash my-4"
            style={{ fontSize: "80px" }}
          ></i>
          <br />
          <h4>عفوا لقد تم اغلاق حسابك وليس لك الصلاحية للدخول على البرنامج</h4>
          <button onClick={LogOut} className="btn btn-danger my-4 p-2 w-25">
            {"  OK "}
          </button>
          <br />
          <br />
        </div>
      </div>
    </>
  );
}
