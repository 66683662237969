import { Outlet, Link, useNavigate, router, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { AppValues, API } from "../../Values";
import { Modal, Button, Dropdown } from "react-bootstrap";
import axios from "axios";
import Cookies from "js-cookie";
import LoginCheck from "../../LoginCheckAdmin/page";
import Header_ from "../compoenents/Header_";
export default function AdminHome() {
  const navigate = useNavigate();
  //===========Check Login ================
  LoginCheck();
  var admininfo = [];
  var AdminName = "";
  var AdminID = "";
  if (Cookies.get("admininfo")) {
    admininfo = JSON.parse(Cookies.get("admininfo"));
    AdminName = admininfo[0].Name;
    AdminID = admininfo[0].ID;
  }
  const [Orders, SetOrders] = useState([]);


  //-------------------------------------------------------------

  return (
    <>
      <Header_ />
      <div dir="rtl" className="row mt-0 text-center0 m-auto ">
        <div className="col-12 p-0"></div>
        <div className="col-master0 col-12 mt-0 bg-light0 px-1 text-center">
          <img
            className="homeimg"
            src="../../../Images/log2.png"
            alt="JSIO Express"
          />
          <h1 className="homecompany">JSIO Express</h1>
          <br />
        </div>
      </div>
    </>
  );
}
