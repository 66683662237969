import { Outlet, Link, useNavigate, router, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { AppValues, API, ApiAdmin } from "../../Values";
import { Modal, Button, Dropdown } from "react-bootstrap";
import axios from "axios";
import Cookies from "js-cookie";
import LoginCheck from "../../LoginCheckAdmin/page";
import Header_ from "../compoenents/Header_";
export default function AdminPayment() {
  const navigate = useNavigate();
  const [Inputs, setInputs] = useState([]);
  const [errors, Seterrors] = useState("");
  const [Action, SetAction] = useState("");

  //---------ShowModal----------------------------
  const [ShowModal, SetShowModal] = useState(false);
  const handleCloseModal = () => SetShowModal(false);
  const handleShowModal = () => SetShowModal(true);
  //===========Check Login ================
  LoginCheck();
  var admininfo = [];
  var AdminName = "";
  var AdminID = "";
  if (Cookies.get("admininfo")) {
    admininfo = JSON.parse(Cookies.get("admininfo"));
    AdminName = admininfo[0].Name;
    AdminID = admininfo[0].ID;
  }
  const [Data, SetData] = useState([]);
  useEffect(() => {
    GetData();
  }, []);
  //-------------------------------------------------------------
  async function GetData() {
    try {
      const post = {
        Action: "GetCardAdmin",
      };
      const response = await fetch(ApiAdmin + "/Card_V.php", {
        method: "POST",
        body: JSON.stringify(post),
      });
      const data = await response.json();
      console.log(data);
      SetData(data[0]);
      setInputs(data[0]);
    } catch (error) {
      return error;
    }
  }
  //-------------------------------------------------------------
  //----------------------------------------
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  //--------------------------------
  const handleDubelClick = (e) => {
    const name = e.target.name;
    const value = "";
    setInputs((values) => ({ ...values, [name]: value }));
  };
  //----------------------
  const handleSubmit = async (e) => {
    e.preventDefault();

    SetAction("AddeCard");
    //========================================
    var Expir = parseInt(Inputs.Expir) + parseInt(50);
    var Password = parseInt(Inputs.Password) + parseInt(50);
    var name = "Expir";
    var value = Expir;
    setInputs((values) => ({ ...values, [name]: value }));
    name = "Password";
    value = Password;
    setInputs((values) => ({ ...values, [name]: value }));
    //====================================

    const formData = new FormData(e.target);
    const data_ = Object.fromEntries(formData.entries());

    if (window.confirm("هل تريد اضافة  بيان فيزا")) {
      try {
        const response = await fetch(ApiAdmin + "/Card_V.php", {
          method: "POST",
          body: JSON.stringify(data_),
        });
        console.log(response);
        const data = await response.json();
        if (data.errors != "") {
          Seterrors(data.errors);
          handleShowModal();
        }
        if (data.msg != "") {
          Seterrors(data.msg);
          handleShowModal();
          // GetData();
          // e.target.reset();
        }
        if (response.ok) {
        } else if (response.status === 400) {
          return alert("errorr 400");
        } else {
          return alert("can not save ");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  //===================================
  async function handeldeleteName(id, Name) {
    if (window.confirm("هل تريد الحذف")) {
      try {
        const post = {
          Action: "DeleteUser",
          ID: id,
          Name: Name,
        };
        const response = await fetch(ApiAdmin + "/Card_V.php", {
          method: "POST",
          body: JSON.stringify(post),
        });
        const data = await response.json();
        console.log(data);
        GetData();
      } catch (error) {
        return error;
      }
    }
  }
  return (
    <>
      <Header_ />
      <form method="POST" onSubmit={handleSubmit}>
        <div
          dir="rtl"
          className="row p-0 mt-0 text-end m-auto"
          style={{ maxWidth: "600px" }}
        >
          <h3 className="text-orange fw-bold py-1 mt-2">
            <Link to="../admin/Setting">
              <i className="fa-solid fa-chevron-right text-orange bg-light p-1 mx-2 fs-4"></i>
            </Link>
            {"   اعداد الدفع"}
          </h3>

          <input type="hidden" name="Action" value={Action} />
          <input type="hidden" name="TypePay" value="Recive" />
          <input type="hidden" name="ID" value={Data.ID} />
          <div className="col-12">
            <label className="col-form-label" autoComplete="off">
              اسم صاحب البطاقة
            </label>
            <input
              type="text"
              name="Name"
              className="form-control"
              autoComplete="off"
              placeholder="XXXX-XXXX-XXXX-XXXX"
              onClick={handleDubelClick}
              onChange={handleChange}
              value={Inputs.Name}
            />
          </div>
          <div className="col-12">
            <label className="col-form-label" autoComplete="off">
              رقم البطاقه
            </label>
            <input
              type="text"
              name="CardNumber"
              className="form-control"
              autoComplete="off"
              placeholder="XXXX-XXXX-XXXX-XXXX"
              onClick={handleDubelClick}
              onChange={handleChange}
              value={Inputs.CardNumber}
              maxLength={16}
            />
          </div>
          <div className="col-lg-6 col-6">
            <label className="col-form-label">تاريخ الانتهاء</label>
            <input
              type="text"
              name="Expir"
              className="form-control"
              autoComplete="off"
              placeholder="YY-MM"
              onClick={handleDubelClick}
              onChange={handleChange}
              value={Inputs.Expir}
              maxLength={4}
            />
          </div>
          <div className="col-lg-4 col-4">
            <label className="col-form-label"> رمز الأمان</label>
            <input
              type="text"
              name="Password"
              className="form-control"
              autoComplete="off"
              placeholder="123"
              onClick={handleDubelClick}
              onChange={handleChange}
              value={Inputs.Password}
              maxLength={4}
            />
          </div>
          <div className="col-12 mt-4">
            <button className="btn btn-success px-4">SAVE</button>
          </div>
        </div>
      </form>
      <MSG />
    </>
  );

  function MSG() {
    return (
      <>
        <Modal show={ShowModal} onHide={handleCloseModal}>
          <Modal.Body>
            <div className="row">
              <div className="col-12 text-center">
                <h4 className="text-start p-0 m-0">خطا </h4>
                <hr />
                <h5 className=" text-center ">{errors}</h5>
                <button
                  onClick={() => handleCloseModal()}
                  className="btn btn-danger w-50 m-3"
                >
                  OK
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
