import { Outlet, Link, useNavigate, router, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { AppValues, API, ApiAdmin } from "../../Values";
import { Modal, Button, Dropdown } from "react-bootstrap";
import axios from "axios";
import Cookies from "js-cookie";
import LoginCheck from "../../LoginCheckAdmin/page";
import Header_ from "../compoenents/Header_";
export default function Database() {
  const navigate = useNavigate();
  const [Inputs, setInputs] = useState([]);
  const [errors, Seterrors] = useState("");
  //---------ShowModal----------------------------
  const [ShowModal, SetShowModal] = useState(false);
  const handleCloseModal = () => SetShowModal(false);
  const handleShowModal = () => SetShowModal(true);
  //===========Check Login ================
  LoginCheck();
  var admininfo = [];
  var AdminName = "";
  var AdminID = "";
  if (Cookies.get("admininfo")) {
    admininfo = JSON.parse(Cookies.get("admininfo"));
    AdminName = admininfo[0].Name;
    AdminID = admininfo[0].ID;
  }
  const [Data, SetData] = useState([]);
  useEffect(() => {
    GetUsers();
  }, []);
  //-------------------------------------------------------------
  async function GetUsers() {
    try {
      const post = {
        Action: "UsersList",
      };
      const response = await fetch(ApiAdmin + "/users.php", {
        method: "POST",
        body: JSON.stringify(post),
      });
      const data = await response.json();
      console.log(data);
      SetData(data);
    } catch (error) {
      return error;
    }
  }
  //-------------------------------------------------------------
  //----------------------------------------
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  //--------------------------------
  const handleDubelClick = (e) => {
    const name = e.target.name;
    const value = "";
    setInputs((values) => ({ ...values, [name]: value }));
  };
  //----------------------
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data_ = Object.fromEntries(formData.entries());

    if (window.confirm("هل تريد اضافة مستخدم جديد")) {
      try {
        const response = await fetch(ApiAdmin + "/users.php", {
          method: "POST",
          body: JSON.stringify(data_),
        });
        console.log(response);
        const data = await response.json();
        if (data.errors != "") {
          Seterrors(data.errors);
          handleShowModal();
        }
        if (data.msg == "Success") {
          GetUsers();
          e.target.reset();
        }
        if (response.ok) {
        } else if (response.status === 400) {
          return alert("errorr 400");
        } else {
          return alert("can not save ");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  //===================================
  async function handeldeleteName(id, Name) {
    if (window.confirm("هل تريد الحذف")) {
      try {
        const post = {
          Action: "DeleteUser",
          ID: id,
          Name: Name,
        };
        const response = await fetch(ApiAdmin + "/users.php", {
          method: "POST",
          body: JSON.stringify(post),
        });
        const data = await response.json();
        console.log(data);
        GetUsers();
      } catch (error) {
        return error;
      }
    }
  }
  return (
    <>
      <Header_ />

      <div
        dir="rtl"
        className="row p-0 mt-0 text-end m-auto"
        style={{ maxWidth: "600px" }}
      >
        <h3 className="text-orange fw-bold py-1 mt-2">
          <Link to="../admin/Setting">
            <i className="fa-solid fa-chevron-right text-orange bg-light p-1 mx-2 fs-4"></i>
          </Link>
          {"Database Setting "}
        </h3>

        <form method="POST" onSubmit={handleSubmit}>
          <input type="hidden" name="Action" value="AddeUser" />
          <div className="col-12">
            <div className="card bg-light shadow">
              <div className="card-body text-center">
                <label className="col-form-label"> Backup Database </label>
                <input
                  type="file"
                  name="file"
                  className="form-control"
                  autoComplete="off"
                />
                <button className=" btn btn-danger my-4 w-50"> Backup Database </button>
              </div>
            </div>

            <div className="card bg-light shadow mt-4">
              <div className="card-body text-center">
                <label className="col-form-label"> Restore Database </label><br />
               
                <button className=" btn btn-green my-4 w-50"> Restore Database  </button>
              </div>
            </div>
          </div>
        </form>
      </div>

      <MSG />
    </>
  );

  function MSG() {
    return (
      <>
        <Modal show={ShowModal} onHide={handleCloseModal}>
          <Modal.Body>
            <div className="row">
              <div className="col-12 text-center">
                <h4 className="text-start p-0 m-0">خطا </h4>
                <hr />
                <h5 className=" text-center ">{errors}</h5>
                <button
                  onClick={() => handleCloseModal()}
                  className="btn btn-danger w-50 m-3"
                >
                  OK
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
