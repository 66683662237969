import { Outlet, Link, useNavigate, router, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { AppValues, API, ApiAdmin } from "../../Values";
import { Modal, Button, Dropdown } from "react-bootstrap";
import axios from "axios";
import Cookies from "js-cookie";
import LoginCheck from "../../LoginCheckAdmin/page";
import Header_ from "../compoenents/Header_";
export default function AdminOrders() {
  const navigate = useNavigate();
  const [SearchText, SetSearchText] = useState("");
  const [number_order, Setnumber_order] = useState("");
  const [type_order, Settype_order] = useState("");
  const [customer_name, Setcustomer_name] = useState("");
  const [address, Setaddress] = useState("");
  const [Orderstatue, SetOrderstatue] = useState("");

  //---------ShowModal----------------------------
  const [ShowModal, SetShowModal] = useState(false);
  const handleCloseModal = () => SetShowModal(false);
  const handleShowModal = () => SetShowModal(true);
  //===========Check Login ================
  LoginCheck();
  var admininfo = [];
  var AdminName = "";
  var AdminID = "";
  if (Cookies.get("admininfo")) {
    admininfo = JSON.parse(Cookies.get("admininfo"));
    AdminName = admininfo[0].Name;
    AdminID = admininfo[0].ID;
  }
  const [Orders, SetOrders] = useState([]);
  useEffect(() => {
    GetOrders();
    // //Implementing the setInterval method
    // const interval = setInterval(() => {
    //   GetOrders();
    // }, 10000);
  }, []);

  //-------------------------------------
  async function GetOrders() {
    try {
      const post = {
        Action: "AdminOrders",
      };
      const response = await fetch(API.Orders, {
        method: "POST",
        body: JSON.stringify(post),
      });
      const data = await response.json();
      // console.log(data);
      SetOrders(data);
    } catch (error) {
      return error;
    }
  }
  //-------------------------------------
  async function GetOrdersSearch() {
    try {
      SetOrders([]);
      const post = {
        SearchText: SearchText,
        Action: "OrdersSearch",
      };
      const response = await fetch(ApiAdmin + "/orders.php", {
        method: "POST",
        body: JSON.stringify(post),
      });
      const data = await response.json();
      console.log(data);
      SetOrders(data);
    } catch (error) {
      return error;
    }
  }
  //-------------------------------------------------------------
  async function EditeOrder(Edite) {
    if (admininfo[0].add != "on") {
      var Edite_ = `هل تريد  ${Edite}  الاوردر`;
      if (window.confirm(Edite_)) {
        try {
          const post = {
            number_order: number_order,
            Edite: Edite,
            Action: "EditeOrder",
          };
          const response = await fetch(ApiAdmin + "/orders.php", {
            method: "POST",
            body: JSON.stringify(post),
          });
          const data = await response.json();
          console.log(data);
          handleCloseModal();
          GetOrders();
        } catch (error) {
          return error;
        }
      }
    } else {
      alert("عفوا ليس لك الصلاحية بهذة العملية");
    }
  }
  //======================================

  const handleChange = (e) => {
    SetSearchText(e.target.value);
    console.log(SearchText);
    // GetOrdersSearch();
  };
  const handleTextClear = (e) => {
    SetSearchText("");
  };
  const handelOrderChange = (
    number_order,
    type_order,
    customer_name,
    address,
    statue
  ) => {
    Setnumber_order(number_order);
    Settype_order(type_order);
    Setcustomer_name(customer_name);
    Setaddress(address);
    SetOrderstatue(statue);
    handleShowModal();
  };

  return (
    <>
      <Header_ />
      <div dir="rtl" className="row p-0 m-0 mt-0 text-center">
        <h3 className="text-orange fw-bold py-1">الاوردرات</h3>
        <div className="col-12 my-2">
          <div className="div-SearchText">
            <input
              type="text"
              name="SearchText"
              className="SearchText"
              onChange={handleChange}
              onClick={handleTextClear}
              value={SearchText}
              autoComplete="off"
              // placeholder="بحث برقم تليفون او رقم اوردر او اسم مندوب"
            />
            <button className="btn btn-warning m-0" onClick={GetOrdersSearch}>
              Search
            </button>
          </div>
        </div>
        <div className="col-12 mt-0 bg-light0 text-center">
          <div className="table-responsive">
            <table
              dir="rtl"
              className="table table-striped  m-auto"
              style={{ minWidth: "1350px" }}
            >
              <thead>
                <tr>
                  <th width="120px">الحالة</th>
                  <th width="100">رقم</th>
                  <th width="150px">النوع</th>
                  <th width="250px">الطلب</th>
                  <th width="150px">من</th>
                  <th width="150px">الى</th>
                  <th width="80px">مسافة</th>
                  <th width="250px">العميل</th>
                  <th width="300px">العنوان</th>
                  <th width="100px">موبايل</th>
                  <th width="50px">التقييم</th>
                </tr>
              </thead>
              <tbody>
                {Orders.map((x, index) => {
                  var address = x.city + " - " + x.state + " - " + x.address;
                  var classStatu = "";

                  if (x.statue == "بانتظار العروض") {
                    classStatu = "bg-warning";
                  }
                  if (x.statue == "بانتظار التاكيد") {
                    classStatu = "bg-warning";
                  }
                  if (x.statue == "انتظار الموافقة") {
                    classStatu = "bg-warning";
                  }

                  if (x.statue == "تم الموافقة") {
                    classStatu = "bg-warning";
                  }
                  if (x.statue == "ملغى") {
                    classStatu = "bg-secondary text-white";
                  }
                  if (x.statue == "جارى التاكيد") {
                    classStatu = "bg-info text-white";
                  }
                  if (x.statue == "جارى التنفيذ") {
                    classStatu = "bg-orangeDark text-white";
                  }
                  if (x.statue == "جارى التوصيل") {
                    classStatu = "bg-blue2 text-white";
                  }
                  if (x.statue == "تم التوصيل") {
                    classStatu = "bg-green text-white";
                  }
                  return (
                    <tr key={index}>
                      <td className="text-center px-1">
                        <label
                          className={`w-100 rounded ${classStatu}`}
                          onClick={() =>
                            handelOrderChange(
                              x.number_order,
                              x.type_order,
                              x.customer_name,
                              x.address,
                              x.statue
                            )
                          }
                        >
                          {x.statue}
                        </label>
                      </td>
                      <td>{x.number_order}</td>
                      <td>{x.type_order}</td>
                      <td>{x.items}</td>
                      <td>{x.from_plase}</td>
                      <td>{x.to_plase}</td>
                      <td>{x.distance}</td>
                      <td>{x.customer_name}</td>
                      <td>{address}</td>
                      <td>{x.mobail}</td>
                      <td>{x.reviews}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <ActionModal />
    </>
  );

  function ActionModal() {
    return (
      <>
        <Modal show={ShowModal} onHide={handleCloseModal}>
          <Modal.Body>
            <h6 className="text-danger" onClick={handleCloseModal}>
              X
            </h6>
            <div className="row mt-4 px-4">
              <div className="col-12 text-center">
                <h6>رقم الاوردر :{number_order}</h6>
                <h6>نوع الاوردر :{type_order}</h6>
                <h6> العميل :{customer_name}</h6>
                <h6> عنوان العميل :{address}</h6>
                {Orderstatue != "تم التوصيل" && (
                  <>
                    <h5
                      onClick={() => EditeOrder("تسليم")}
                      className="bg-green text-white p-2 mt-4"
                    >
                      {" تسليم الاوردر "}
                    </h5>

                    <h5
                      onClick={() => EditeOrder("الغاء")}
                      className="bg-danger text-white p-2"
                    >
                      الغاء الاوردر
                    </h5>

                    <h5
                      onClick={() => EditeOrder("حذف")}
                      className="bg-dark text-white p-2"
                    >
                      حذف الاوردر
                    </h5>
                  </>
                )}
                <br />
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
