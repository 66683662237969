import { Outlet, Link, useNavigate, router, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { AppValues, API, ApiAdmin } from "../../Values";
import { Modal, Button, Dropdown } from "react-bootstrap";
import axios from "axios";
import Cookies from "js-cookie";
import LoginCheck from "../../LoginCheckAdmin/page";
import Header_ from "../compoenents/Header_";
export default function CompanyInfo() {
  const navigate = useNavigate();
  const [errors, Seterrors] = useState("");
  const [Action, SetAction] = useState("AddCompanyInfo");
  const [Inputs, setInputs] = useState([
    {
      Name: "1",
      NameEn: "2",
      Address: "3",
      Mobil: "4",
      Email: "5",
      WhatsApp: "6",
      TekTok: "7",
      Youtube: "8",
      CommercialRegister: "9",
      TaxCard: "10",
      Description: "11",
    },
  ]);
  console.log(Inputs);
  //---------ShowModal----------------------------
  const [ShowModal, SetShowModal] = useState(false);
  const handleCloseModal = () => SetShowModal(false);
  const handleShowModal = () => SetShowModal(true);
  //===========Check Login ================
  LoginCheck();
  var admininfo = [
    {
      AdminName: "",
      AdminID: "",
    },
  ];
  var AdminName = "";
  var AdminID = "";
  if (Cookies.get("admininfo")) {
    admininfo = JSON.parse(Cookies.get("admininfo"));
    AdminName = admininfo[0].Name;
    AdminID = admininfo[0].ID;
  }
  const [Data, SetData] = useState([]);
  useEffect(() => {
    GetData();
  }, []);
  //-------------------------------------------------------------
  async function GetData() {
    try {
      const post = {
        Action: "GetCompanyInfo",
      };
      const response = await fetch(ApiAdmin + "/CompanyInfo.php", {
        method: "POST",
        body: JSON.stringify(post),
      });
      const data = await response.json();
      console.log(data);
      SetData(data[0]);
      setInputs(data[0]);
    } catch (error) {
      return error;
    }
  }
  //-------------------------------------------------------------
  //----------------------------------------
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  //--------------------------------
  const handleDubelClick = (e) => {
    const name = e.target.name;
    const value = "";
    setInputs((values) => ({ ...values, [name]: value }));
  };
  //----------------------

  const handleSubmit = async (e) => {
    e.preventDefault();

    //====================================
    const formData = new FormData(e.target);
    const data_ = Object.fromEntries(formData.entries());

    if (window.confirm("هل تريد الحفظ   ")) {
      try {
        const response = await fetch(ApiAdmin + "/CompanyInfo.php", {
          method: "POST",
          body: JSON.stringify(data_),
        });
        console.log(response);
        const data = await response.json();
        if (data.errors != "") {
          Seterrors(data.errors);
          handleShowModal();
        }
        if (data.msg != "") {
          Seterrors(data.msg);
          handleShowModal();
          // GetData();
          // e.target.reset();
        }
        if (response.ok) {
        } else if (response.status === 400) {
          return alert("errorr 400");
        } else {
          return alert("can not save ");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  //===================================
  // async function handeldeleteName(id, Name) {
  //   if (window.confirm("هل تريد الحذف")) {
  //     try {
  //       const post = {
  //         Action: "DeleteCompanyInfo",
  //         ID: id,
  //         Name: Name,
  //       };
  //       const response = await fetch(ApiAdmin + "/CompanyInfo.php", {
  //         method: "POST",
  //         body: JSON.stringify(post),
  //       });
  //       const data = await response.json();
  //       console.log(data);
  //       GetData();
  //     } catch (error) {
  //       return error;
  //     }
  //   }
  // }

  return (
    <>
      <Header_ />
      <form method="POST" onSubmit={handleSubmit}>
        <div
          dir="rtl"
          className="row p-0 mt-0 text-end m-auto"
          style={{ maxWidth: "600px" }}
        >
          <h3 className="text-orange fw-bold py-1 mt-2">
            <Link to="../admin/Setting">
              <i className="fa-solid fa-chevron-right text-orange bg-light p-1 mx-2 fs-4"></i>
            </Link>
            {"   اعداد بيانات الشركة"}
          </h3>

          <input type="hidden" name="Action" value={Action} />
          <input type="hidden" name="ID" value={Data.ID} />
          <div className="col-6">
            <label className="col-form-label" autoComplete="off">
              اسم الشركة
            </label>
            <input
              type="text"
              name="Name"
              className="form-control"
              autoComplete="off"
              onClick={handleDubelClick}
              onChange={handleChange}
              value={Inputs.Name}
            />
          </div>
          <div className="col-6">
            <label className="col-form-label" autoComplete="off">
              اسم الشركة انجليزى
            </label>
            <input
              type="text"
              name="NameEn"
              className="form-control"
              autoComplete="off"
              placeholder=""
              onClick={handleDubelClick}
              onChange={handleChange}
              value={Inputs.NameEn}
            />
          </div>
          <div className="col-lg-12 col-12">
            <label className="col-form-label"> العنوان</label>
            <input
              type="text"
              name="Address"
              className="form-control"
              autoComplete="off"
              placeholder=""
              onClick={handleDubelClick}
              onChange={handleChange}
              value={Inputs.Address}
            />
          </div>
          <div className="col-lg-6 col-12">
            <label className="col-form-label"> رقم الموبايل</label>
            <input
              type="text"
              name="Mobil"
              className="form-control"
              autoComplete="off"
              placeholder=""
              onClick={handleDubelClick}
              onChange={handleChange}
              value={Inputs.Mobil}
            />
          </div>
          <div className="col-lg-6 col-12">
            <label className="col-form-label" autoComplete="off">
              البريد الالكترونى
            </label>
            <input
              type="text"
              name="Email"
              className="form-control"
              autoComplete="off"
              placeholder=""
              onClick={handleDubelClick}
              onChange={handleChange}
              value={Inputs.Email}
            />
          </div>
          <div className="col-12">
            <label className="col-form-label" autoComplete="off">
              رابط صفحة الواتس
            </label>
            <input
              type="text"
              name="WhatsApp"
              className="form-control"
              autoComplete="off"
              placeholder=""
              onClick={handleDubelClick}
              onChange={handleChange}
              value={Inputs.WhatsApp}
            />
          </div>
          <div className="col-12">
            <label className="col-form-label" autoComplete="off">
              رابط صفحة التيك توك
            </label>
            <input
              type="text"
              name="TekTok"
              className="form-control"
              autoComplete="off"
              placeholder=""
              onClick={handleDubelClick}
              onChange={handleChange}
              value={Inputs.TekTok}
            />
          </div>
          <div className="col-12">
            <label className="col-form-label" autoComplete="off">
              رابط صفحة اليوتيوب
            </label>
            <input
              type="text"
              name="Youtube"
              className="form-control"
              autoComplete="off"
              placeholder=""
              onClick={handleDubelClick}
              onChange={handleChange}
              value={Inputs.Youtube}
            />
          </div>
          <div className="col-6">
            <label className="col-form-label" autoComplete="off">
              السجل التجارى
            </label>
            <input
              type="text"
              name="CommercialRegister"
              className="form-control"
              autoComplete="off"
              placeholder=""
              onClick={handleDubelClick}
              onChange={handleChange}
              value={Inputs.CommercialRegister}
            />
          </div>
          <div className="col-6">
            <label className="col-form-label" autoComplete="off">
              البطاقة الضريببة
            </label>
            <input
              type="text"
              name="TaxCard"
              className="form-control"
              autoComplete="off"
              placeholder=""
              onClick={handleDubelClick}
              onChange={handleChange}
              value={Inputs.TaxCard}
            />
          </div>
          <div className="col-12">
            <label className="col-form-label" autoComplete="off">
              وصف الشركة
            </label>
            <input
              type="text"
              name="Description"
              className="form-control"
              autoComplete="off"
              placeholder=""
              onClick={handleDubelClick}
              onChange={handleChange}
              value={Inputs.Description}
            />
          </div>
          <div className="col-12 mt-4 text-center">
            <button className="btn btn-success px-4 w-50">SAVE</button>
          </div>

          <br />
          <br />
          <br />
          <br />
        </div>
      </form>
      <MSG />
    </>
  );

  function MSG() {
    return (
      <>
        <Modal show={ShowModal} onHide={handleCloseModal}>
          <Modal.Body>
            <div className="row">
              <div className="col-12 text-center">
                <h4 className="text-start p-0 m-0">خطا </h4>
                <hr />
                <h5 className=" text-center ">{errors}</h5>
                <button
                  onClick={() => handleCloseModal()}
                  className="btn btn-danger w-50 m-3"
                >
                  OK
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
