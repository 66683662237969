import React, { useState, useEffect } from "react";
import { Outlet, Link, useNavigate, router, useParams } from "react-router-dom";
import { AppValues, API, PayMoob } from "../Values";
import { useLocation } from "react-router-dom";

const PaymentCallBack = () => {
  const navigate = useNavigate();

  const [transactionStatus, setTransactionStatus] = useState(null);

  const handleCallback = (event) => {
    if (event.origin === "https://accept.paymobsolutions.com") {
      const data = JSON.parse(event.data);
      setTransactionStatus(data);
    }
  };

  useEffect(() => {
    window.addEventListener("message", handleCallback);
    return () => {
      window.removeEventListener("message", handleCallback);
    };
  }, []);
  let [success, Setsuccess] = useState("");
  let [id, Setid] = useState("");
  let [amount_cents, Setamount_cents] = useState("");
  let [integration_id, Setintegration_id] = useState("");
  let [order, Setorder] = useState("");
  let [hmac, Sethmac] = useState("");
  let [amount, Setamount] = useState("500");
  let [currency, Setcurrency] = useState("EG");
  let [created_at, Setcreated_at] = useState("2024-01-01");
  let [data_message, Setdata_message] = useState("");

  let location = useLocation();
  useEffect(() => {
    // Extract query parameters from the URL
    let queryParams = new URLSearchParams(location.search);
    Setsuccess(queryParams.get("success"));
    Setid(queryParams.get("id"));
    Setamount_cents(queryParams.get("amount_cents"));
    Setintegration_id(queryParams.get("integration_id"));
    Setorder(queryParams.get("order"));
    Sethmac(queryParams.get("hmac"));
    Setcreated_at(queryParams.get("created_at"));
    Setamount(queryParams.get("amount_cents"));
    Setcurrency(queryParams.get("currency"));
    Setdata_message(queryParams.get("data.message"));
    // Log the details for debugging
    console.log("success :", success);
    console.log("Transaction ID:", integration_id);
    console.log("Order ID:", order);
    console.log("Amount:", amount);
    console.log("hmac:", hmac);
    console.log(queryParams);
    // Process the data
    // if (success === "true") {
    // Handle successful payment
    //   alert("Payment Successful!");
    // } else {
    // Handle failed payment
    //   alert("Payment Failed.");
    // }
  }, [location]);

  /*
  id=241119389
  pending=false
  amount_cents=10000
  success=true
  is_auth=false
  is_capture=false
  is_standalone_payment=true
  is_voided=false
  is_refunded=false
  is_3d_secure=true
  integration_id=4891042
  profile_id=1009769
  has_parent_transaction=false
  order=270912332
  created_at=2024-12-03T12%3A53%3A38.148230
  currency=EGP
  merchant_commission=0
  discount_details=%5B%5D
  is_void=false
  is_refund=false
  error_occured=false
  refunded_amount_cents=0
  captured_amount=0
  updated_at=2024-12-03T12%3A53%3A57.921880
  is_settled=false
  bill_balanced=false
  is_bill=false
  owner=1877825
  data.message=Approved
  source_data.type=card
  source_data.pan=2346
  source_data.sub_type=MasterCard
  acq_response_code=00
  txn_response_code=APPROVED
  hmac=b264b381342cb43fe2e1d054628a7637e97637b2b0f5b15bf20fcec8f4cefb31c520735254816f38e09f8ddb5dd23cd67de2590b031285492db91aa7d1d4afc0
*/

  return (
    <>
      <div
        dir="rtl"
        className="row mt-0 text-center0 m-auto "
        style={{ maxWidth: "600px" }}
      >
        <div className="col-1 py-1 bg-light ">
          <i
            className="fa-solid fa-chevron-right  mt-2 fs-4"
            onClick={() => navigate("../Login")}
          ></i>
        </div>
        <div className="col-11 py-1 text-center bg-light">
          <h4> Transaction Status </h4>
        </div>
        <br />

        {success == "true" && <PaySuccess />}
        {success != "true" && <PayFiald />}
        <div className="col-12 text-center pt-4 mt-4">
          <img src="../../logo.png" alt="logo" style={{ width: "100px" }} />
        </div>
      </div>
    </>
  );

  function PaySuccess() {
    return (
      <div className="col-12 text-center mt-4 pt-4">
        <h2 class="fw-bold  text-center my-2 stc fw-bold text-green">
          {"Success "}
        </h2>
        {/* <img
          src="../../Images/success_.jpg"
          alt="success_"
          className="my-4"
          style={{ width: "200px" }}
        /> */}
        <i
          class="fa-solid fa-circle-check text-green my-4"
          style={{ fontSize: "80px" }}
        ></i>
        <h4 class="fw-bold  text-center my-3 stc"> تمت العملية بنجاح </h4>
        <h1 dir="ltr">
          {parseFloat(amount / 100)}
          <span className="px-1 text-orange">{currency}</span>
        </h1>
        <h6 className="text-secondary stc my-4">  اجمالى المبلغ المحول </h6>
        <label className="text-secondary0 stc my-2 text-center w-100">
          {"  رقم العملية : "}
          <span dir="rtl">{id}</span>
        </label>
        <label className="text-secondary0 stc my-2  text-center w-100">
          التاريخ : <span dir="rtl">{created_at}</span>
        </label>
      </div>
    );
  }

  function PayFiald() {
    return (
      <div className="col-12 text-center mt-4 pt-4">
   <i
          class="fa-solid fa-xmark text-danger"
          style={{ fontSize: "100px" }}
        ></i> 


        <h4 class="fw-bold  text-center my-3 stc"> العملية فاشلة </h4>
        <h6 class=" text-center mt-2 mb-4"> {data_message}</h6>

        <h1 dir="ltr">
          {parseFloat(amount / 100)}
          <span className="px-1 text-orange">{currency}</span>
        </h1>
        <h6 className="text-secondary stc"> اجمالى المبلغ المحول </h6>
        <label className="text-secondary0 stc text-center w-100  my-4">
          {"  رقم العملية : "}
          <span dir="rtl">{id}</span>
        </label>
        <label className="text-secondary0 stc text-center w-100 mb-2">
          التاريخ : <span dir="rtl">{created_at}</span>
        </label>
      </div>
    );
  }
};
export default PaymentCallBack;
