import { Outlet, Link, useNavigate, router, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { AppValues, API, ApiAdmin } from "../../Values";
import { Modal, Button, Dropdown } from "react-bootstrap";
import axios from "axios";
import Cookies from "js-cookie";
import LoginCheck from "../../LoginCheckAdmin/page";
import Header_ from "../compoenents/Header_";
export default function AdminUsers() {
  const navigate = useNavigate();
  const [Inputs, setInputs] = useState([]);
  const [errors, Seterrors] = useState("");
  //---------ShowModal----------------------------
  const [ShowModal, SetShowModal] = useState(false);
  const handleCloseModal = () => SetShowModal(false);
  const handleShowModal = () => SetShowModal(true);
  //===========Check Login ================
  LoginCheck();
  var admininfo = [];
  var AdminName = "";
  var AdminID = "";
  if (Cookies.get("admininfo")) {
    admininfo = JSON.parse(Cookies.get("admininfo"));
    AdminName = admininfo[0].Name;
    AdminID = admininfo[0].ID;
  }
  const [Data, SetData] = useState([]);
  useEffect(() => {
    GetUsers();
  }, []);
  //-------------------------------------------------------------
  async function GetUsers() {
    try {
      const post = {
        Action: "UsersList",
      };
      const response = await fetch(ApiAdmin + "/users.php", {
        method: "POST",
        body: JSON.stringify(post),
      });
      const data = await response.json();
      SetData(data);
    } catch (error) {
      return error;
    }
  }
  //-------------------------------------------------------------
  //----------------------------------------
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  //--------------------------------
  const handleDubelClick = (e) => {
    const name = e.target.name;
    const value = "";
    setInputs((values) => ({ ...values, [name]: value }));
  };
  //----------------------
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data_ = Object.fromEntries(formData.entries());
    // return console.log(data_);
    if (window.confirm("هل تريد اضافة مستخدم جديد")) {
      try {
        const response = await fetch(ApiAdmin + "/users.php", {
          method: "POST",
          body: JSON.stringify(data_),
        });
        console.log(response);
        const data = await response.json();
        if (data.errors != "") {
          Seterrors(data.errors);
          handleShowModal();
        }
        if (data.msg != "") {
          GetUsers();
          // e.target.reset();
          console.log(data.msg);
        }
        if (response.ok) {
        } else if (response.status === 400) {
          return alert("errorr 400");
        } else {
          return alert("can not save ");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  //===================================
  async function handeldeleteName(id, Name) {
    if (window.confirm("هل تريد الحذف")) {
      try {
        const post = {
          Action: "DeleteUser",
          ID: id,
          Name: Name,
        };
        const response = await fetch(ApiAdmin + "/users.php", {
          method: "POST",
          body: JSON.stringify(post),
        });
        const data = await response.json();
        console.log(data);
        GetUsers();
      } catch (error) {
        return error;
      }
    }
  }
  return (
    <>
      <Header_ />

      <div dir="rtl" className="row p-3 mt-0 text-end m-auto bg-light">
        <h3 className="text-orange fw-bold py-1 mt-2">
          <Link to="../admin/Setting">
            <i className="fa-solid fa-chevron-right text-orange bg-light p-1 mx-2 fs-4"></i>
          </Link>
          المستخدمين{" "}
        </h3>

        <div className="col-lg-6 col-12 p-1">
          <div className="row m-1 bg-white">
            <form method="POST" onSubmit={handleSubmit}>
              <input type="hidden" name="Action" value="AddeUser" />
              <div className="col-12">
                <label className="col-form-label">الصلاحية</label>
                <select type="text" name="Premision" className="form-control">
                  <option value=""></option>
                  <option value="admin">admin</option>
                  <option value="user">user</option>
                </select>
              </div>
              <div className="col-12">
                <label className="col-form-label" autoComplete="off">
                  الاسم
                </label>
                <input
                  type="text"
                  name="Name"
                  className="form-control"
                  autoComplete="off"
                />
              </div>
              <div className="col-12">
                <label className="col-form-label">كلمة السر</label>
                <input
                  type="text"
                  name="Password"
                  className="form-control"
                  autoComplete="off"
                  onClick={handleDubelClick}
                />
              </div>
              <div className="col-12">
                <h4>الصلاحيات</h4>
              </div>

              <div className="col-12">
                <div dir="rtl" className="row bg-light p-1 m-0">
                  <div className="col-lg-3 col-md-3 col-6">
                    <input
                      type="checkbox"
                      id="orders"
                      name="orders"
                      className="form-control0"
                      // checked={inputs.active}
                      // onChange={(event) => {
                      //  setinputs({ ...inputs, active: event.target.checked });
                      // }}

                      // onChange={handleChange}
                    />
                    <label htmlFor="orders" className="px-1">
                      الاوردرات
                    </label>
                  </div>

                  <div className="col-lg-3 col-md-3 col-6">
                    <input
                      type="checkbox"
                      name="mwzfeen"
                      id="mwzfeen"
                      className="form-control0"
                      // checked={inputs.active}
                      // onChange={(event) => {
                      //  setinputs({ ...inputs, active: event.target.checked });
                      // }}

                      // onChange={handleChange}
                    />
                    <label htmlFor="mwzfeen" className=" px-1">
                      الموظفين
                    </label>
                  </div>

                  <div className="col-lg-3 col-md-3 col-6">
                    <input
                      type="checkbox"
                      id="deller"
                      name="deller"
                      className="form-control0"
                      // checked={inputs.active}
                      // onChange={(event) => {
                      //  setinputs({ ...inputs, active: event.target.checked });
                      // }}

                      // onChange={handleChange}
                    />
                    <label htmlFor="deller" className="px-1">
                      المندوبين
                    </label>
                  </div>

                  <div className="col-lg-3 col-md-3 col-6">
                    <input
                      type="checkbox"
                      id="customer"
                      name="customer"
                      className="form-control0"
                      // checked={inputs.active}
                      // onChange={(event) => {
                      //  setinputs({ ...inputs, active: event.target.checked });
                      // }}

                      // onChange={handleChange}
                    />
                    <label htmlFor="customer" className="px-1">
                      العملاء
                    </label>
                  </div>
                  <div className="col-lg-3 col-md-3 col-6">
                    <input
                      type="checkbox"
                      id="shkawa"
                      name="shkawa"
                      className="form-control0"
                      // checked={inputs.active}
                      // onChange={(event) => {
                      //  setinputs({ ...inputs, active: event.target.checked });
                      // }}

                      // onChange={handleChange}
                    />
                    <label htmlFor="shkawa" className="px-1">
                      الشكاوى
                    </label>
                  </div>
                  <div className="col-lg-3 col-md-3 col-6">
                    <input
                      type="checkbox"
                      id="kobon"
                      name="kobon"
                      className="form-control0"
                      // checked={inputs.active}
                      // onChange={(event) => {
                      //  setinputs({ ...inputs, active: event.target.checked });
                      // }}

                      // onChange={handleChange}
                    />
                    <label htmlFor="kobon" className="px-1">
                      الكوبونات
                    </label>
                  </div>
                  <div className="col-lg-3 col-md-3 col-6">
                    <input
                      type="checkbox"
                      id="add"
                      name="add"
                      className="form-control0"
                      // checked={inputs.active}
                      // onChange={(event) => {
                      //  setinputs({ ...inputs, active: event.target.checked });
                      // }}

                      // onChange={handleChange}
                    />
                    <label htmlFor="add" className="px-1">
                      اضافة
                    </label>
                  </div>
                  <div className="col-lg-3 col-md-3 col-6">
                    <input
                      type="checkbox"
                      name="delete"
                      id="delete"
                      className="form-control0"
                      // checked={inputs.active}
                      // onChange={(event) => {
                      //  setinputs({ ...inputs, active: event.target.checked });
                      // }}

                      // onChange={handleChange}
                    />
                    <label htmlFor="delete" className="px-1">
                      حذف
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-12 my-4">
                <button className="btn btn-success px-4">اضافة</button>
              </div>
            </form>
          </div>
        </div>
        <div className="col-lg-6 col-12 p-1">
          <div className="row m-1 bg-white">
            <div className="col-lg-12 col-12 mt-2 p-1">
              <div className="table-responsive">
                <table
                  dir="rtl"
                  className="table table-striped  m-auto "
                  style={{ Width: "920px" }}
                >
                  <thead>
                    <tr>
                      <th width="250px">الاسم</th>
                      <th width="200px">الصلاحية</th>
                      <th width="50px">اوردرات</th>
                      <th width="50px">موظفين</th>
                      <th width="50px">مندوبين</th>
                      <th width="50px">عملاء</th>
                      <th width="50px">شكاوى</th>
                      <th width="50px">كوبونات</th>
                      <th width="50px">حذف</th>
                      <th width="50px">اضافة</th>
                      <th width="50px">حذف</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Data.map((x, index) => {
                      return (
                        <tr key={index}>
                          <td>{x.Name}</td>
                          <td>{x.Premision}</td>
                          <td>{x.orders}</td>
                          <td>{x.mwzfeen}</td>
                          <td>{x.deller}</td>
                          <td>{x.customer}</td>
                          <td>{x.shkawa}</td>
                          <td>{x.kobon}</td>
                          <td>{x.delete_}</td>
                          <td>{x.add_}</td>

                          <td className="text-center px-1">
                            <button
                              className=" btn btn-sm text-danger fw-bold w-100 rounded p-0 px-2 delete"
                              onClick={() => {
                                handeldeleteName(x.ID, x.Name);
                              }}
                            >
                              حذف
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <MSG />
    </>
  );

  function MSG() {
    return (
      <>
        <Modal show={ShowModal} onHide={handleCloseModal}>
          <Modal.Body>
            <div className="row">
              <div className="col-12 text-center">
                <h4 className="text-start p-0 m-0">خطا </h4>
                <hr />
                <h5 className=" text-center ">{errors}</h5>
                <button
                  onClick={() => handleCloseModal()}
                  className="btn btn-danger w-50 m-3"
                >
                  OK
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
