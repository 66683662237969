import { Outlet, Link, useNavigate, router, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { AppValues, API } from "../../Values";
import { Modal, Button, Dropdown } from "react-bootstrap";
import axios from "axios";
import Cookies from "js-cookie";
import LoginCheck from "../../LoginCheckAdmin/page";
import Header_ from "../compoenents/Header_";
export default function CustomerSupport() {
  const navigate = useNavigate();
  //===========Check Login ================
  LoginCheck();
  var admininfo = [];
  var AdminName = "";
  var AdminID = "";
  if (Cookies.get("admininfo")) {
    admininfo = JSON.parse(Cookies.get("admininfo"));
    AdminName = admininfo[0].Name;
    AdminID = admininfo[0].ID;
  }
  const [Orders, SetOrders] = useState([]);
  useEffect(() => {
    GetOrders();
  }, []);

  //-------------------------------------------------------------
  async function GetOrders() {
    try {
      const post = {
        Name: AdminName,
        ID: AdminID,
        statue: "",
        Action: "CustomerOrders",
      };
      const result = await fetch(API.Orders, {
        method: "POST",
        body: JSON.stringify(post),
      });
      const body = await result.json();
      console.log(body);
      SetOrders(body);
    } catch (error) {
      return error;
    }
  }

  return (
    <>
      <Header_ />
      <div
        dir="rtl"
        className="row text-center m-auto"
        style={{ maxWidth: "800px" }}
      >
        <h3 className="text-orange fw-bold py-1">Setting</h3>

        {/* -------------- */}
        <div className="col-lg-3 col-md-4 col-6">
          <div className="card shadow m-1">
            <Link to="../admin/Users">
              <div className="card-body">
                <div
                  className="Icon-imgContainer "
                  style={{ color: "#55C99D", background: "#DCF5EA" }}
                >
                  <i className="fa-solid fa-user-secret mt-2"></i>
                </div>
                <h6>المستخدمين</h6>
              </div>
            </Link>
          </div>
        </div>
        {/* -------------- */}
        <div className="col-lg-3 col-md-4 col-6">
          <div className="card shadow m-1">
            <Link to="../CompanyInfo">
              <div className="card-body">
                <div
                  className="Icon-imgContainer"
                  style={{ color: "#3F83F8", background: "#CCE9FB" }}
                >
                  <i className="fa-solid fa-building mt-2"></i>
                </div>
                <h6>بيانات الشركة</h6>
              </div>
            </Link>
          </div>
        </div>
        {/* -------------- */}
        <div className="col-lg-3 col-md-4 col-6">
          <div className="card shadow m-1">
            <Link to="../Database">
              <div className="card-body">
                <div
                  className="Icon-imgContainer"
                  style={{ color: "#EF6242", background: "#FEECDC" }}
                >
                  <i className="fa-solid fa-database mt-2"></i>
                </div>
                <h6>قاعدة البيانات</h6>
              </div>
            </Link>
          </div>
        </div>
        {/* -------------- */}
        {/* <div className="col-lg-3 col-md-4 col-6">
          <div className="card shadow  m-1">
            <Link to="">
              <div className="card-body">
                <div
                  className="Icon-imgContainer"
                  style={{ color: "#E49E1B", background: "#FFFAE6" }}
                >
                  <i className="fa-sharp fa-solid fa-timeline mt-3"></i>
                </div>
                <h6>السجل</h6>
              </div>
            </Link>
          </div>
        </div> */}
        {/* -------------- */}
        <div className="col-lg-3 col-md-4 col-6">
          <div className="card shadow m-1">
            <Link to="../admin/Payment">
              <div className="card-body">
                <div
                  className="Icon-imgContainer"
                  style={{ color: "#E49E1B", background: "#FFFAE6" }}
                >
                  <i className="fa-sharp fa-solid fa-timeline mt-3"></i>
                </div>
                <h6>اعداد الدفع</h6>
              </div>
            </Link>
          </div>
        </div>
        {/* -------------- */}
        <div className="col-lg-3 col-md-4 col-6">
          <div className="card shadow m-1">
            <Link to="../Destanse">
              <div className="card-body">
                <div
                  className="Icon-imgContainer"
                  style={{ color: "#7954AD", background: "#F0ECF5" }}
                >
                  <i class="fa-solid fa-location-dot mt-3"></i>
                </div>
                <h6>اعداد المسافات</h6>
              </div>
            </Link>
          </div>
        </div>
        {/* -------------- */}
        <div className="col-lg-3 col-md-4 col-6">
          <div className="card shadow m-1">
            <Link to="../ApiSetting">
              <div className="card-body">
                <div
                  className="Icon-imgContainer"
                  style={{ color: "#CC2127", background: "#F3CDCF" }}
                >
                  <i class="fa-solid fa-server mt-3"></i>
                </div>
                <h6> Api Setting</h6>
              </div>
            </Link>
          </div>
        </div>
        {/* -------------- */}
        {/* <div className="col-lg-3 col-md-4 col-6">
          <div className="card shadow m-1">
            <Link to="../KobonSetting">
              <div className="card-body">
                <div
                  className="Icon-imgContainer"
                  style={{ color: "#005F93", background: "#D4F1F8" }}
                >
                  <i class="fa-solid fa-layer-group mt-3"></i>
                </div>
                <h6> الكوبونات</h6>
              </div>
            </Link>
          </div>
        </div> */}
        {/* -------------- */}
      </div>
    </>
  );
}
