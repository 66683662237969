import Cookies from "js-cookie";
import { useState, useEffect } from "react";
import { Outlet, Link, router, useParams, useNavigate } from "react-router-dom";
import { AppValues, API, ApiUrl } from "../../Values";
export default function AccountCheck() {
  const navigate = useNavigate();
  var userinfo = [];
  var Username = "";
  var UserID = "";
  if (Cookies.get("userinfo")) {
    userinfo = JSON.parse(Cookies.get("userinfo"));
    Username = userinfo[0].Name;
    UserID = userinfo[0].ID;
  }

  useEffect(() => {
    CheckAccount_();
  }, []);

  async function CheckAccount_() {
    try {
      const post = {
        Name: Username,
        ID: UserID,
        Action: "CheckAccount",
      };
      const result = await fetch(API.DellerAccount, {
        method: "POST",
        body: JSON.stringify(post),
      });
      const data = await result.json();
      console.log(data);
      if (data.msg != "enable") {
   
        return navigate("../AccountDisable");
      }
    } catch (error) {
      return error;
    }
  }
}
