export function Reviews0() {
  return (
    <>
      <i style={{fontSize:"12px"}} className="fa fa-star text-secondary"></i>
      <i style={{fontSize:"12px"}}className="fa fa-star text-secondary"></i>
      <i style={{fontSize:"12px"}}className="fa fa-star text-secondary"></i>
      <i style={{fontSize:"12px"}}className="fa fa-star text-secondary"></i>
      <i style={{fontSize:"12px"}}className="fa fa-star text-secondary"></i>
    </>
  );
}
export function Reviews1() {
  return (
    <>
      <i style={{fontSize:"12px"}}className="fa fa-star text-orangeyalow"></i>
      <i style={{fontSize:"12px"}}className="fa fa-star text-secondary"></i>
      <i style={{fontSize:"12px"}}className="fa fa-star text-secondary"></i>
      <i style={{fontSize:"12px"}}className="fa fa-star text-secondary"></i>
      <i style={{fontSize:"12px"}}className="fa fa-star text-secondary"></i>
    </>
  );
}

export function Reviews2() {
  return (
    <>
      <i style={{fontSize:"12px"}}className="fa fa-star text-orangeyalow"></i>
      <i style={{fontSize:"12px"}}className="fa fa-star text-orangeyalow"></i>
      <i style={{fontSize:"12px"}}className="fa fa-star text-secondary"></i>
      <i style={{fontSize:"12px"}}className="fa fa-star text-secondary"></i>
      <i style={{fontSize:"12px"}}className="fa fa-star text-secondary"></i>
    </>
  );
}

export function Reviews3() {
  return (
    <>
      <i style={{fontSize:"12px"}}className="fa fa-star text-orangeyalow"></i>
      <i style={{fontSize:"12px"}}className="fa fa-star text-orangeyalow"></i>
      <i style={{fontSize:"12px"}}className="fa fa-star text-orangeyalow"></i>
      <i style={{fontSize:"12px"}}className="fa fa-star text-secondary"></i>
      <i style={{fontSize:"12px"}}className="fa fa-star text-secondary"></i>
    </>
  );
}

export function Reviews4() {
  return (
    <>
      <i style={{fontSize:"12px"}}className="fa fa-star text-orangeyalow"></i>
      <i style={{fontSize:"12px"}}className="fa fa-star text-orangeyalow"></i>
      <i style={{fontSize:"12px"}}className="fa fa-star text-orangeyalow"></i>
      <i style={{fontSize:"12px"}}className="fa fa-star text-orangeyalow"></i>
      <i style={{fontSize:"12px"}}className="fa fa-star text-secondary"></i>
    </>
  );
}

export function Reviews5() {
  return (
    <>
      <i style={{fontSize:"12px"}}className="fa fa-star text-orangeyalow"></i>
      <i style={{fontSize:"12px"}}className="fa fa-star text-orangeyalow"></i>
      <i style={{fontSize:"12px"}}className="fa fa-star text-orangeyalow"></i>
      <i style={{fontSize:"12px"}}className="fa fa-star text-orangeyalow"></i>
      <i style={{fontSize:"12px"}}className="fa fa-star text-orangeyalow"></i>
    </>
  );
}
