import { Outlet, Link, useNavigate, router, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { AppValues, API, ApiAdmin } from "../../Values";
import { Modal, Button, Dropdown } from "react-bootstrap";
import axios from "axios";
import Cookies from "js-cookie";
import LoginCheck from "../../LoginCheckAdmin/page";
import Header_ from "../compoenents/Header_";
export default function KobonSetting() {
  const navigate = useNavigate();
  const [Inputs, setInputs] = useState([]);
  const [errors, Seterrors] = useState("");
  //---------ShowModal----------------------------
  const [ShowModal, SetShowModal] = useState(false);
  const handleCloseModal = () => SetShowModal(false);
  const handleShowModal = () => SetShowModal(true);
  //===========Check Login ================
  LoginCheck();
  var admininfo = [];
  var AdminName = "";
  var AdminID = "";
  if (Cookies.get("admininfo")) {
    admininfo = JSON.parse(Cookies.get("admininfo"));
    AdminName = admininfo[0].Name;
    AdminID = admininfo[0].ID;
  }
  const [Data, SetData] = useState([]);
  useEffect(() => {
    GetUsers();
  }, []);
  //-------------------------------------------------------------
  async function GetUsers() {
    try {
      const post = {
        Action: "UsersList",
      };
      const response = await fetch(ApiAdmin + "/users.php", {
        method: "POST",
        body: JSON.stringify(post),
      });
      const data = await response.json();
      console.log(data);
      SetData(data);
    } catch (error) {
      return error;
    }
  }
  //-------------------------------------------------------------
  //----------------------------------------
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  //--------------------------------
  const handleDubelClick = (e) => {
    const name = e.target.name;
    const value = "";
    setInputs((values) => ({ ...values, [name]: value }));
  };
  //----------------------
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data_ = Object.fromEntries(formData.entries());

    if (window.confirm("هل تريد اضافة مستخدم جديد")) {
      try {
        const response = await fetch(ApiAdmin + "/users.php", {
          method: "POST",
          body: JSON.stringify(data_),
        });
        console.log(response);
        const data = await response.json();
        if (data.errors != "") {
          Seterrors(data.errors);
          handleShowModal();
        }
        if (data.msg == "Success") {
          GetUsers();
          e.target.reset();
        }
        if (response.ok) {
        } else if (response.status === 400) {
          return alert("errorr 400");
        } else {
          return alert("can not save ");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  //===================================
  async function handeldeleteName(id, Name) {
    if (window.confirm("هل تريد الحذف")) {
      try {
        const post = {
          Action: "DeleteUser",
          ID: id,
          Name: Name,
        };
        const response = await fetch(ApiAdmin + "/users.php", {
          method: "POST",
          body: JSON.stringify(post),
        });
        const data = await response.json();
        console.log(data);
        GetUsers();
      } catch (error) {
        return error;
      }
    }
  }
  return (
    <>
      <Header_ />

      <div
        dir="rtl"
        className="row p-0 mt-0 text-end m-auto"
        style={{ maxWidth: "600px" }}
      >
        <h3 className="text-orange fw-bold py-1 mt-2">  
        <Link to="../admin/Setting">
            <i className="fa-solid fa-chevron-right text-orange bg-light p-1 mx-2 fs-4"></i>
          </Link> 
         الكوبونات </h3>

        <form method="POST" onSubmit={handleSubmit}>
          <input type="hidden" name="Action" value="AddeUser" />
          <div className="col-12">
            <label className="col-form-label"> الفئة</label>
            <select type="text" name="Premision" className="form-control">
              <option value=""></option>
              <option value="عميل">عميل</option>
              <option value="مندوب">مندوب</option>
            </select>
          </div>
          <div className="col-12">
            <label className="col-form-label" autoComplete="off">
              الخصم
            </label>
            <input
              type="text"
              name="Name"
              className="form-control"
              autoComplete="off"
            />
          </div>
          <div className="col-12">
            <label className="col-form-label">كلمة السر</label>
            <input
              type="text"
              name="Password"
              className="form-control"
              autoComplete="off"
              onClick={handleDubelClick}
            />
          </div>
          <div className="col-12 mt-4">
            <button className="btn btn-success px-4">اضافة</button>
          </div>
        </form>
        <div className="col-lg-12 col-12 mt-4">
          <table
            dir="rtl"
            className="table table-striped  m-auto "
            cellspacing="1"
            style={{ Width: "920px" }}
          >
            <thead>
              <tr>
                <th width="250px">الاسم</th>
                <th width="200px">الصلاحية</th>
                <th width="50px">حذف</th>
              </tr>
            </thead>
            <tbody>
              {Data.map((x, index) => {
                return (
                  <tr key={index}>
                    <td>{x.Name}</td>
                    <td>{x.Premision}</td>
                    <td className="text-center px-1">
                      <button
                        className=" btn btn-sm btn-danger w-100 rounded p-0 px-2 delete"
                        onClick={() => {
                          handeldeleteName(x.ID, x.Name);
                        }}
                      >
                        حذف
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      <MSG />
    </>
  );

  function MSG() {
    return (
      <>
        <Modal show={ShowModal} onHide={handleCloseModal}>
          <Modal.Body>
            <div className="row">
              <div className="col-12 text-center">
                <h4 className="text-start p-0 m-0">خطا </h4>
                <hr />
                <h5 className=" text-center ">{errors}</h5>
                <button
                  onClick={() => handleCloseModal()}
                  className="btn btn-danger w-50 m-3"
                >
                  OK
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
