import { Outlet, Link, useNavigate, router, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { AppValues, API, ApiAdmin } from "../../Values";
import { Modal, Button, Dropdown } from "react-bootstrap";
import axios from "axios";
import Cookies from "js-cookie";
import LoginCheck from "../../LoginCheckAdmin/page";
import Header_ from "../compoenents/Header_";
import {
  Reviews0,
  Reviews1,
  Reviews2,
  Reviews3,
  Reviews4,
  Reviews5,
} from "../../Reviews/page";
export default function AdminDeller() {
  const navigate = useNavigate();

  //---------ShowModal----------------------------

  const [TypeModal, SetTypeModal] = useState("");
  const [ShowModal, SetShowModal] = useState(false);
  const handleCloseModal = () => SetShowModal(false);
  const handleShowModal = () => SetShowModal(true);
  const [Inputs, setInputs] = useState([]);
  const [SearchText, SetSearchText] = useState("");
  const [TextMSG, SetTextMSG] = useState("");
  const [Reviews, SetReviews] = useState("0");
  //===========Check Login ================
  LoginCheck();
  var admininfo = [];
  var AdminName = "";
  var AdminID = "";
  if (Cookies.get("admininfo")) {
    admininfo = JSON.parse(Cookies.get("admininfo"));
    AdminName = admininfo[0].Name;
    AdminID = admininfo[0].ID;
  }
  const [Data, SetData] = useState([]);
  const [DellerName, SetDellerName] = useState("");
  const [DellerID, SetDellerID] = useState("");

  useEffect(() => {
    GetData();
  }, []);

  //Implementing the setInterval method
  // const interval = setInterval(() => {
  //   GetData();
  // }, 20000);
  //-------------------------------------------------------------
  async function GetData() {
    try {
      const post = {
        Action: "AdminDellers",
        SearchText: SearchText,
      };
      // SetData([]);
      const response = await fetch(ApiAdmin + "/deller.php", {
        method: "POST",
        body: JSON.stringify(post),
      });
      const data = await response.json();
      console.log(data);
      SetData(data);
    } catch (error) {
      return error;
    }
  }

  //----------------------------------------
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  //----------------------------------------
  const handleTextClear = (e) => {
    const name = e.target.name;
    const value = "";
    setInputs((values) => ({ ...values, [name]: value }));
  };

  //-------------------------------------------------------------
  const handleChangeSearchText = (e) => {
    SetSearchText(e.target.value);
    console.log(SearchText);
  };
  const handleTextClearSearchText = (e) => {
    SetSearchText("");
  };

  //-------------------------------------------------------------
  const handleChangeTextMSG = (e) => {
    SetTextMSG(e.target.value);
    console.log(TextMSG);
  };
  const handleTextClearTextMSG = (e) => {
    SetTextMSG("");
  };

  //-------------------------------------------------------------
  async function Active(ID, Name, Statue) {
    var Statue_ = "";
    if (Statue == "disable") {
      Statue_ = "enable";
    }
    if (Statue == "enable") {
      Statue_ = "disable";
    }
    try {
      const post = {
        Action: "ActiveDeller",
        ID: ID,
        Name: Name,
        Statue: Statue_,
      };
      const response = await fetch(ApiAdmin + "/deller.php", {
        method: "POST",
        body: JSON.stringify(post),
      });
      const data = await response.json();
      console.log(data);
      SetData(data);
      // GetData();
    } catch (error) {
      return error;
    }
  }

  //-------------------------------------------------------------
  async function DeleteDeller(ID, Name) {
    if (window.confirm(`هل تريد حذف  ${Name} `)) {
      try {
        const post = {
          Action: "Delete",
          ID: ID,
          Name: Name,
        };
        const response = await fetch(ApiAdmin + "/deller.php", {
          method: "POST",
          body: JSON.stringify(post),
        });
        const data = await response.json();
        console.log(data);
        SetData(data);
      } catch (error) {
        return error;
      }
    }
  }

  //-------------------------------------------------------------
  async function ChangeKobone(ID, Name) {
    SetTypeModal("Kobon");
    SetDellerID(ID);
    SetDellerName(Name);
    handleShowModal();
  }

  //======================================
  //-------------------------------------------------------------
  const [Datakobon, SetDatakobon] = useState([]);

  async function Getkobon(NameID, Name) {
    try {
      ChangeKobone(NameID, Name);
      const post = {
        Action: "GetkobonToName",
        account_type: "مندوب",
        NameID: NameID,
        Name: Name,
      };
      SetDatakobon([]);
      const response = await fetch(ApiAdmin + "/kobon.php", {
        method: "POST",
        body: JSON.stringify(post),
      });
      const data = await response.json();
      console.log(data);
      SetDatakobon(data);
    } catch (error) {
      return error;
    }
  }
  //----------------------
  async function DeleteKobon(ID, Descount, Name, NameID) {
    if (window.confirm(`هل تريد حذف كوبون  ${Name} `)) {
      try {
        const post = {
          Action: "DeleteKobon_",
          ID: ID,
          Descount: Descount,
          account_type: "مندوب",
          NameID: NameID,
          Name: Name,
        };
        const response = await fetch(ApiAdmin + "/kobon.php", {
          method: "POST",
          body: JSON.stringify(post),
        });
        const data = await response.json();
        console.log(data);
        Getkobon(NameID, Name);
        GetData();
      } catch (error) {
        return error;
      }
    }
  }
  //----------------------
  //----------------------
  //----------------------
  const handleSubmitAddKobon = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data_ = Object.fromEntries(formData.entries());

    if (window.confirm(`هل تريد اضافة كوبون  ${DellerName} `)) {
      try {
        const response = await fetch(ApiAdmin + "/kobon.php", {
          method: "POST",
          body: JSON.stringify(data_),
        });
        console.log(response);
        const data = await response.json();
        console.log(data);

        if (data.errors != "") {
          alert(data.errors);
        }
        if (data.msg != "") {
          console.log(data.msg);
          handleCloseModal();
          // alert("تم اضافة الكوبون");
          GetData();
        }

        if (response.ok) {
        } else if (response.status === 400) {
          return alert("errorr 400");
        } else {
          return alert("can not save ");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  //----------------------

  //-------------------------------------------------------------
  async function ChangeKhasm(ID, Name) {
    SetTypeModal("Khasm");
    SetDellerID(ID);
    SetDellerName(Name);
    handleShowModal();
  }

  //----------------------
  const handleSubmitKhasm = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data_ = Object.fromEntries(formData.entries());
    if (window.confirm(`هل تريد عمل خصم الى  ${DellerName} `)) {
      try {
        const response = await fetch(ApiAdmin + "/deller.php", {
          method: "POST",
          body: JSON.stringify(data_),
        });

        console.log(response);
        const data = await response.json();
        if (data.errors != "") {
          alert(data.errors);
        }
        if (data.msg == "Success") {
          console.log(data.msg);
          handleCloseModal();
          GetData();
        }

        if (response.ok) {
        } else if (response.status === 400) {
          return alert("errorr 400");
        } else {
          return alert("can not save ");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  //===============================================================
  //-------------------------------------------------------------
  async function ChangeAlert(ID, Name) {
    SetTypeModal("Alert");
    SetDellerID(ID);
    SetDellerName(Name);
    handleShowModal();
  }
  //----------------------
  const handleSubmitAlert = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data_ = Object.fromEntries(formData.entries());
    if (window.confirm(`هل تريد ارسال تنبية  ${DellerName} `)) {
      try {
        const response = await fetch(ApiAdmin + "/Alerts.php", {
          method: "POST",
          body: JSON.stringify(data_),
        });
        console.log(response);
        const data = await response.json();
        if (data.errors != "") {
          alert(data.errors);
        }
        if (data.msg == "Success") {
          console.log(data.msg);
          handleCloseModal();
        }

        if (response.ok) {
        } else if (response.status === 400) {
          return alert("errorr 400");
        } else {
          return alert("can not save ");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  //===============================================================

  async function ChangeAlertAll(ID, Name) {
    SetTypeModal("AlertAll");
    handleShowModal();
  }
  //-------------------------------------------------------------
  const handleSubmitAlertAll = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data_ = Object.fromEntries(formData.entries());
    if (window.confirm(`هل تريد ارسال تنبية الى كل المندوبين `)) {
      try {
        const response = await fetch(ApiAdmin + "/Alerts.php", {
          method: "POST",
          body: JSON.stringify(data_),
        });
        console.log(response);
        const data = await response.json();
        if (data.errors != "") {
          alert(data.errors);
        }
        if (data.msg == "Success") {
          console.log(data.msg);
          handleCloseModal();
        }

        if (response.ok) {
        } else if (response.status === 400) {
          return alert("errorr 400");
        } else {
          return alert("can not save ");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  //======================================================
  //-------------------------------------------------------------
  async function ChangeSubScribe(ID, Name, reviews) {
    SetTypeModal("SubScribe");
    SetDellerID(ID);
    SetDellerName(Name);
    SetReviews(reviews);
    handleShowModal();
  }
  //---------------------------
  const handleSubmiSubScribe = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data_ = Object.fromEntries(formData.entries());
    if (window.confirm(`هل تريد تقييم  ${DellerName} `)) {
      try {
        const response = await fetch(ApiAdmin + "/Reviews.php", {
          method: "POST",
          body: JSON.stringify(data_),
        });
        console.log(response);
        const data = await response.json();
        if (data.errors != "") {
          alert(data.errors);
        }
        if (data.msg != "") {
          console.log(data.msg);
          handleCloseModal();

          GetData();
        }

        if (response.ok) {
        } else if (response.status === 400) {
          return alert("errorr 400");
        } else {
          return alert("can not save ");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  //=============================================
  return (
    <>
      <Header_ />
      <div dir="rtl" className="row p-0 m-0 mt-0 text-center">
        <h3 className="text-orange fw-bold py-1">المندوبين</h3>
        <div className="col-lg-2  col-4 my-2">
          <button
            className="btn btn-info text-white p-1 px-1 m-0 fw-bold"
            onClick={() => ChangeAlertAll()}
          >
            تنبية للمندوبين
          </button>
        </div>
        <div className="col-lg-10  col-8 my-2">
          <div className="div-SearchText">
            <input
              type="text"
              name="SearchText"
              className="SearchText"
              onChange={handleChangeSearchText}
              onClick={handleTextClearSearchText}
              value={SearchText}
              autoComplete="off"
              // placeholder="بحث برقم تليفون او رقم اوردر او اسم مندوب"
            />
            <button className="btn btn-warning  m-0" onClick={GetData}>
              Search
            </button>
          </div>
        </div>

        <div className="col-12 mt-0 bg-light0 text-center">
          <div className="table-responsive">
            <table
              dir="rtl"
              className="table table-striped  m-auto"
              style={{ minWidth: "1220px" }}
            >
              <thead>
                <tr>
                  <th width="100px"> الحالة</th>
                  <th width="60px"> العمل </th>

                  {admininfo[0].Premision == "admin" && (
                    <>
                      <th width="60px"> كوبون</th>
                      <th width="60px"> خصم</th>
                    </>
                  )}

                  <th width="60px"> تنبية</th>
                  <th width="250px">الاسم</th>
                  <th width="250px">عنوان</th>
                  <th width="100px">بطاقة</th>
                  <th width="100px">موبايل</th>
                  <th width="60px">الرخصة</th>
                  <th width="60px">السيارة</th>
                  <th width="120px">اللوحات</th>
                  <th width="150px">العمل</th>
                  <th width="80px"> الرصيد</th>
                  <th width="80px"> الاوردرات</th>
                  <th width="130px">التقييم</th>
                  <th width="30px">حذف</th>
                </tr>
              </thead>
              <tbody>
                {Data.length < 1 && (
                  <tr>
                    <td
                      className="text-center fs-6 fw-bold text-danger"
                      colSpan="15"
                    >
                      لا توجد بيانات
                    </td>
                  </tr>
                )}

                {Data.length > 0 &&
                  Data.map((x, index) => {
                    var classStatu = "";
                    var classwarking = "";
                    var Statu = "";
                    var classRassed = "";
                    var mony_ = parseFloat(x.Mony);
                    if (mony_ > 0) {
                      classRassed = "text-green";
                    } else {
                      classRassed = "text-danger";
                    }

                    if (x.Statue == "enable") {
                      classStatu = "bg-green";
                      Statu = "نشط";
                    }
                    if (x.Statue != "enable") {
                      classStatu = "bg-danger text-white";
                      Statu = "غير نشط";
                    }

                    //------------------------------------------
                    if (x.warking == "يعمل") {
                      classwarking = "text-green";
                    }
                    if (x.warking != "يعمل") {
                      classwarking = "text-danger";
                    }

                    //-----------------------------------------
                    return (
                      <tr key={index}>
                        <td className="text-center px-1">
                          <label
                            onClick={() => Active(x.ID, x.Name, x.Statue)}
                            className={`p-0 w-100 fw-bold rounded ${classStatu}`}
                          >
                            {Statu}
                          </label>
                        </td>

                        <td className="text-center px-1">
                          <label
                            className={`p-0 w-100 fw-bold rounded ${classwarking}`}
                          >
                            {x.warking}
                          </label>
                        </td>
                        {/* كوبون + خصم */}
                        {admininfo[0].Premision == "admin" && (
                          <>
                            <td className="text-center text-green fw-bold">
                              <span
                                className="text-center text-green fw-bold"
                                onClick={() => Getkobon(x.ID, x.Name)}
                              >
                                كوبون
                              </span>
                            </td>
                            <td className="text-center text-green fw-bold">
                              <span
                                className="text-center text-danger fw-bold"
                                onClick={() => ChangeKhasm(x.ID, x.Name)}
                              >
                                خصم
                              </span>
                            </td>
                          </>
                        )}
                        {/* ================== */}
                        <td className="text-center text-green fw-bold">
                          <i
                            onClick={() => ChangeAlert(x.ID, x.Name)}
                            className="fa-solid fa-bell m-0 p-0 text-secondary fs-6"
                          ></i>
                        </td>
                        <td>{x.Name}</td>
                        <td>{x.State + " - " + x.Address}</td>
                        <td className="text-center">{x.Card}</td>
                        <td className="text-center">{x.Mobail}</td>
                        <td className="text-center">{x.RkhsaType}</td>
                        <td className="text-center0">{x.CarType}</td>
                        <td className="text-center">
                          {x.LawhatNumber + " " + x.LawhatLeters}
                        </td>
                        <td className="text-center0">{x.CityWork}</td>
                        <td className="text-center">
                          <span className={`fw-bold  ${classRassed}`}>
                            {x.Mony}
                          </span>
                        </td>
                        <td className="text-center">{x.orders}</td>
                        <td
                          className="text-center"
                          onClick={() =>
                            ChangeSubScribe(x.ID, x.Name, x.reviews)
                          }
                        >
                          {x.reviews == "0" && <Reviews0 />}
                          {x.reviews == "1" && <Reviews1 />}
                          {x.reviews == "2" && <Reviews2 />}
                          {x.reviews == "3" && <Reviews3 />}
                          {x.reviews == "4" && <Reviews4 />}
                          {x.reviews == "5" && <Reviews5 />}
                        </td>

                        <td
                          className="text-center"
                          onClick={() => DeleteDeller(x.ID, x.Name)}
                        >
                          <i className=" text-danger fs-6 fw-bold fa-regular fa-xmark"></i>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <ModalSHOW />
    </>
  );

  function ModalSHOW() {
    return (
      <>
        <Modal show={ShowModal} onHide={handleCloseModal}>
          <Modal.Body>
            <h6 className="text-danger" onClick={handleCloseModal}>
              X
            </h6>

            {TypeModal == "Kobon" && <KobonForm />}
            {TypeModal == "Khasm" && <KhasmForm />}
            {TypeModal == "Alert" && <AlertForm />}
            {TypeModal == "AlertAll" && <AlertFormAll />}
            {TypeModal == "SubScribe" && <SubScribe />}
          </Modal.Body>
        </Modal>
      </>
    );
  }

  function KobonForm() {
    return (
      <>
        <form method="POST" onSubmit={handleSubmitAddKobon}>
          <input type="hidden" name="Action" value="Add_Kobon" />
          <input type="hidden" name="Name" value={DellerName} />
          <input type="hidden" name="NameID" value={DellerID} />
          <input type="hidden" name="account_type" value="مندوب" />
          <input type="hidden" name="Top" value="0" />
          <div className="row mt-4 px-4">
            <div className="col-12 text-center">
              <h4 className="text-green">اضافة كوبون </h4>
              <h6> الاسم : {DellerName}</h6>
            </div>
            <div className="col-12">
              <label htmlFor=""> قيمةالكوبون</label>
              <input
                type="number"
                name="Descount"
                className="form-control text-center mt-1"
              />
            </div>
            <div className="col-6">
              <label htmlFor=""> انتهاء الصلاحية</label>
              <input type="date" name="Expir" className="form-control mt-1" />
            </div>
            <div className="col-12 text-center mx-2 mt-4 ">
              <button className="btn btn-green fw-bold fs-5 w-100">
                اضافة كوبون
              </button>
            </div>
            <br />
          </div>
        </form>
        {Datakobon.length > 0 && (
          <>
            <h4>الكوبونات السابقة </h4>
            <table className="table" cellspacing="1">
              <thead>
                <tr>
                  <th>الكوبون</th>
                  <th className="text-center">القيمة</th>
                  <th>حذف</th>
                </tr>
              </thead>
              <tbody>
                {Datakobon.map((x, index) => {
                  return (
                    <tr key={index}>
                      <td className=" p-0">{x.Info}</td>
                      <td className="text-center p-0">{x.Descount}</td>
                      <td
                        className="text-center"
                        onClick={() =>
                          DeleteKobon(x.ID, x.Descount, x.Name, x.NameID)
                        }
                      >
                        <i className=" text-danger fs-6 fw-bold fa-regular fa-xmark p-0"></i>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <br />
            <br />
          </>
        )}
        ;
      </>
    );
  }

  //========================================
  function KhasmForm() {
    return (
      <>
        <form method="POST" onSubmit={handleSubmitKhasm}>
          <input type="hidden" name="Name" value={DellerName} />
          <input type="hidden" name="NameID" value={DellerID} />
          <input type="hidden" name="Action" value="Khasm_" />
          <input type="hidden" name="account_type" value="مندوب" />
          <div className="row mt-4 px-4">
            <div className="col-12 text-center">
              <h4 className="text-danger"> الخصومات </h4>
              <h6> الاسم : {DellerName}</h6>
            </div>
            <div className="col-6">
              <label htmlFor=""> قيمة الخصم</label>
              <input
                type="number"
                name="Khasm"
                className="form-control text-center mt-1"
                autoComplete="off"
              />
            </div>

            <div className="col-12">
              <label htmlFor=""> سبب الخصم</label>
              <input
                type="text"
                name="AlertText"
                className="form-control text-center mt-1"
                autoComplete="off"
              />
            </div>
            <div className="col-12 text-center mx-2 mt-4 ">
              <button className="btn btn-danger fw-bold fs-6 w-100">
                تنفيذ الخصم
              </button>
            </div>

            <br />
          </div>
        </form>
      </>
    );
  }

  //========================================
  function AlertForm() {
    return (
      <>
        <form method="POST" onSubmit={handleSubmitAlert}>
          <input type="hidden" name="Name" value={DellerName} />
          <input type="hidden" name="NameID" value={DellerID} />
          <input type="hidden" name="Action" value="AddAlert" />
          <input type="hidden" name="account_type" value="مندوب" />
          <div className="row mt-4 px-4">
            <div className="col-12 text-center">
              <i className="fa-solid fa-bell m-0 p-0 text-warning fs-1 mb-2"></i>
              <h4 className="text-warning"> ارسال تنبيه </h4>
              <h6> الاسم : {DellerName}</h6>
            </div>
            <div className="col-12">
              <label htmlFor=""> رسالة التنبيه</label>
              <input
                type="text"
                name="AlertText"
                className="form-control text-center mt-1"
                autoComplete="off"
              />
            </div>
            <div className="col-12 text-center mx-2 mt-4 ">
              <button className="btn btn-warning fw-bold fs-6 w-100">
                ارسال التنبية
              </button>
            </div>

            <br />
          </div>
        </form>
      </>
    );
  }
  //========================================
  function AlertFormAll() {
    return (
      <>
        <form method="POST" onSubmit={handleSubmitAlertAll}>
          <input type="hidden" name="Action" value="AddAlertAll" />
          <input type="hidden" name="account_type" value="مندوب" />
          <div className="row mt-4 px-4">
            <div className="col-12 text-center">
              <i className="fa-solid fa-bell m-0 p-0 text-warning fs-1 mb-2"></i>
              <h4 className="text-warning"> ارسال تنبية للمندوبين </h4>
            </div>
            <div className="col-12 mt-4">
              <label htmlFor=""> رسالة التنبيه</label>
              <input
                type="text"
                name="AlertText"
                className="form-control text-center mt-1"
                autoComplete="off"
              />
            </div>
            <div className="col-12 text-center mx-2 mt-4 ">
              <button className="btn btn-warning fw-bold fs-6 w-100">
                ارسال التنبية
              </button>
            </div>

            <br />
          </div>
        </form>
      </>
    );
  }
  //====================================================
  //========================================
  function SubScribe() {
    return (
      <>
        <form method="POST" onSubmit={handleSubmiSubScribe}>
          <input type="hidden" name="account_type" value="مندوب" />
          <input type="hidden" name="Name" value={DellerName} />
          <input type="hidden" name="NameID" value={DellerID} />
          <input type="hidden" name="Action" value="AddReviews" />
          <input type="hidden" name="Reviews" value={Reviews} />
          <div className="row mt-4 px-4">
            <div className="col-12 text-center mb-4">
              <h4 className="text-green"> تقييم المندوبين </h4>
              <h6> الاسم : {DellerName}</h6>

              <h6> التقييم : {Reviews}</h6>
            </div>

            <div className="col-12 text-center">
              <button
                className="btn btn-warning px-2 me-1"
                onClick={() => SetReviews("0")}
              >
                0
              </button>
              <button
                className="btn btn-warning px-2 me-1"
                onClick={() => SetReviews("1")}
              >
                1
              </button>
              <button
                className="btn btn-warning px-2 me-1"
                onClick={() => SetReviews("2")}
              >
                2
              </button>
              <button
                className="btn btn-warning px-2 me-1"
                onClick={() => SetReviews("3")}
              >
                3
              </button>
              <button
                className="btn btn-warning px-2 me-1"
                onClick={() => SetReviews("4")}
              >
                4
              </button>
              <button
                className="btn btn-warning px-2 me-1"
                onClick={() => SetReviews("5")}
              >
                5
              </button>
            </div>
            <div className="col-12 text-center mx-2 mt-4 ">
              <button className="btn btn-danger fw-bold fs-6 w-100">
                تقييم
              </button>
            </div>

            <br />
          </div>
        </form>
      </>
    );
  }
  //==================================================
}
