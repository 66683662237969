import React from "react";

import { ApiAdmin } from "../Values";
import Cookies from "js-cookie";
import { useState, useEffect } from "react";

import ErrorAudio from "../../sound/2.wav";
const Notifications = (props) => {
  var userinfo = [];
  var Username = "";
  var UserID = "";
  if (Cookies.get("userinfo")) {
    userinfo = JSON.parse(Cookies.get("userinfo"));
    Username = userinfo[0].Name;
    UserID = userinfo[0].ID;
  }

  let [notifications, setNotifications] = useState([]);
  let [audio] = useState(new Audio(ErrorAudio));
  let [hasInteracted, setHasInteracted] = useState(false);
  useEffect(() => {
    audio.muted = true;
    let interval = setInterval(() => {
      // document.getElementById("startButton")?.click();
    }, 1000);
  }, []);

  useEffect(() => {
    if (hasInteracted) {
      const interval = setInterval(() => {
        GetNotifications();
      }, 10000);
      return () => clearInterval(interval); // Cleanup interval on component unmount
    }
  }, [hasInteracted]);

  let handleUserInteraction = () => {
    setHasInteracted(true);
    audio.load(); // Preload the sound to ensure playback is ready
  };

  const GetNotifications = async () => {
    try {
      const post = {
        account_type: props.account_type,
        Name: Username,
        NameID: UserID,
        Action: "GetNotifications",
      };
      const response = await fetch(ApiAdmin + "/Alerts.php", {
        method: "POST",
        body: JSON.stringify(post),
      });
      const data = await response.json();
      if (data.data && data.data.length > 0) {
        document.getElementById("startButton")?.click();
        if (data.data.length > notifications.length) {
          console.log(data.data[0].msg);
          if (hasInteracted) {
            audio.muted = false;
            audio
              .play()
              .catch((error) => console.error("Audio playback failed:", error));
          }
        }
        setNotifications(data.data);
      }
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };
  return (
    <>
      {!hasInteracted && (
        <>
          <button
            id="startButton"
            className="btn btn-light text-white0 p-0 m-auto"
            onClick={handleUserInteraction}
          >
            Enable Notifications Sound
          </button>
        </>
      )}
    </>
  );
};

export default Notifications;
