import Cookies from "js-cookie";
import { useState, useEffect } from "react";
import { Outlet, Link, router, useParams, useNavigate } from "react-router-dom";
export default function LoginCheckAdmin() {
  const navigate = useNavigate();
  useEffect(() => {
    Logincheck();
  }, []);

  function Logincheck() {
    if (!Cookies.get("admininfo")) {
      return navigate("../Login");
    }

    
  }
}
